<template>
    <div></div>
</template>
<script>
export default {
    data() {
        return {}
    },
    created() {
        let path = this.$route.query.path;
        this.$router.replace({
           path
        });
    }  
}
</script>
<style scoped>
</style>